import React from 'react';
import Container from "../components/Container";
import {Col, Row} from "antd";
import styled from "styled-components";
import GoHomeButton from "../components/GoHomeButton";
import DocumentTitle from "react-document-title";

const Help = () => (
  <Container>
    <DocumentTitle title="Как пользоваться библиотекой"/>

    <Row type="flex" justify="center" align="middle">
      <Col xs={24} md={12}>
        <GoHomeButton/>
        <br/><br/><br/>

        <h1>Как пользоваться библиотекой</h1>
        <br/>
        <br/>
        <Video>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/PjX6wnB2rfE?rel=0" frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen title="Как пользоваться библиотекой"/>
        </Video>
      </Col>
    </Row>
  </Container>
)

export default Help;

const Video = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
