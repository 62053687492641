import iconDoc from "../img/icon-doc.svg";
import iconPdf from "../img/icon-pdf.svg";
import iconPpt from "../img/icon-ppt.svg";
import iconVid from "../img/icon-video.svg";
import iconNone from "../img/icon-none.svg";
import iconImg from "../img/icon-image.svg";
import iconAudio from "../img/icon-audio.svg";
import iconArch from "../img/icon-archive.svg";
import iconKit from "../img/icon-kit.svg";
import iconLink from "../img/icon-link.svg";

export const checkFileType = (fileType) => {
    switch (fileType) {
        case 'doc':
            return iconDoc;
        case 'ppt':
            return iconPpt;
        case 'pdf':
            return iconPdf;
        case 'video':
        case 'videoLink':
            return iconVid;
        case 'images':
            return iconImg;
        case 'audio':
            return iconAudio;
        case 'archive':
            return iconArch;
        case 'kit':
            return iconKit;
        case 'link':
            return iconLink;
        default:
            return iconNone;
    }
}
