import React, {useState} from 'react';
import styled from 'styled-components';
import MobileMenu from './MobileMenu';
import {Button, Drawer} from 'antd';
import logo from '../img/darkeinu-logo.svg'
import {Link} from "react-router-dom";
import {MenuFoldOutlined} from "@ant-design/icons";

const MobileDrawer = () => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <ButtonWrap onClick={() => setVisible(true)}>
        <Button size="large" type="primary" icon={<MenuFoldOutlined/>}/>
      </ButtonWrap>
      <Drawer
        width={240}
        placement="right"
        closable
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Logo to="/" onClick={() => setVisible(false)}>
          <img src={logo} width={120} alt=""/>
        </Logo>
        <MobileMenu setVisible={setVisible}/>
      </Drawer>
    </>
  );


}

export default MobileDrawer;

const Logo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`

const ButtonWrap = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: .4rem;
  @media screen and (min-width: 992px) {
    display: none;
  }
`

