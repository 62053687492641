import {firestore} from "../firebase";

export const fetchMaterials = async (limit) => {
    const snapshot = await firestore
        .collection('materials')
        .orderBy('createdAt', 'desc')
        .limit(limit)
        .get();

    try {
        return snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
    } catch (error) {
        console.error('Error while fetching materials', error)
    }

};