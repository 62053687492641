import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Loader = () => {
  return (
    <Wrap>
      <Spin size="large" />
    </Wrap>
  );
};

export default Loader;

const Wrap = styled.div`
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;
