import React from 'react';
import styled from 'styled-components';
import {auth} from "../firebase";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../redux/user/user.selectors";
import {Link} from "react-router-dom";

const items = [
  {label: 'Заказать материал', slug: 'order'},
  {label: 'Задать вопрос', slug: 'contact'},
  {label: 'Как пользоваться', slug: 'help'},
]

const MobileMenu = ({currentUser, setVisible}) => (
  <List>

    {currentUser
      ? <LinkBox to="/signin" onClick={() => {
        auth.signOut();
        setVisible(false)
      }}>

        <div>Выйти <small>{currentUser.email}</small></div>
      </LinkBox>
      : <LinkBox to="/signin" onClick={() => setVisible(false)}>
        Войти
      </LinkBox>
    }

    {items.map(item =>
      <LinkBox key={item.slug} to={`/${item.slug}/`} onClick={() => setVisible(false)}>
        {item.label}
      </LinkBox>
    )}

  </List>
)

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(MobileMenu)


const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;

const LinkBox = styled(Link)`
  display: flex;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  width: 100%;
  line-height: 1;

  small {
    font-size: 65%;
  }
`