import React from 'react';
import styled from 'styled-components';
import Filters from "./Filters";
import SearchBar from "./SearchBar";
import {Col, Row} from 'antd';

const Header = () => (
  <>
    <HeaderInnerWrapper search>
      <SearchBar/>
    </HeaderInnerWrapper>
    <HeaderInnerWrapper>
      <Row gutter={10}>
        <Col xs={24} md={15}>
          <Filters type="themes"/>
        </Col>
        <Col xs={24} md={9}>
          <Filters type="formats"/>
        </Col>
      </Row>
    </HeaderInnerWrapper>
  </>
)

export default Header;

const HeaderInnerWrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: ${props => props.search ? '#fafafa' : null};
  box-shadow: ${props => props.search ? null : '0 1px 1px rgba(0,0,0,.025)'};
  padding: .5rem 3%;
  display: ${props => props.filters ? 'none' : 'block'};

  @media only screen and (min-width: 992px) {
    padding: .75rem 4.5%;
  }
  @media only screen and (min-width: 1600px) {
    padding: 1rem 15%;
  }

`;


