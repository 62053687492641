import React from 'react';
import Container from "../components/Container";
import {Button, Col, Row} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";
import GoHomeButton from "../components/GoHomeButton";
import DocumentTitle from "react-document-title";

const Order = () => (
  <Container>
    <DocumentTitle title='Как заказать материал'/>

    <Row type="flex" justify="center" align="middle">
      <Col xs={24} md={12}>
        <GoHomeButton/>
        <br/><br/><br/>

        <h1>Как заказать материал</h1>
        <br/>
        <p>Учебно-методический комплекс &laquo;Даркейну&raquo; включает широкий круг пособий&nbsp;&mdash; учебники,
          рабочие тетради, наглядные
          пособия, дидактические игры. Эти пособия разработаны в&nbsp;соответствии с&nbsp;программой, охватывающей все
          годы
          обучения в&nbsp;школе по&nbsp;учебным предметам: Тора/Танах, Еврейская традиция. Для 1-6 классов имеются
          пособия по
          курсу Основы хасидизма. Наряду с&nbsp;пособиями для школы, можно заказать рабочие тетради для старшей группы
          детского сада.</p>

        <p>
          <Button type="primary" ghost href="http://www.darkeinu.org/program/">
            Подробнее о&nbsp;программе &laquo;Даркейну&raquo; <ArrowRightOutlined/>
          </Button>
        </p>

        <p>
          Для заказа интересующих вас материалов необходимо:<br/>
          А.&nbsp;Зарегистрироваться в&nbsp;Электронной библиотеке.<br/>
          Б.&nbsp;Обратиться (письменно) к&nbsp;менеджеру &laquo;Даркейну&raquo; Наоми Шестаковой.<br/>
          Адрес электронной почты: <a href="mailto:naomishes@darkeinu.org">naomishes@darkeinu.org</a><br/>
          Телефон/WhatsApp/Viber: +972&nbsp;542&nbsp;322&nbsp;454<br/>
        </p>
      </Col>
    </Row>
  </Container>
)

export default Order;
