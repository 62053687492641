import firebase from "firebase/app";
import firebaseConfig from "./config";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics';

export default firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();

// take new user auth data (+ extra data) and save it to firestore
export const saveUserToFirestore = async (user, userData) => {
	if (!user) return;
	
	const userRef = firestore.doc(`users/${user.uid}`);
	
	try {
		await userRef.set({...userData})
	} catch (e) {
		console.error('Error on saving user to database', e)
	}
	
};
