// project global styles
import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`

	h1, h2, h3 {
		color: #373737;
		line-height: 1.35;
		letter-spacing: .25px;
		margin-bottom: 0;
		margin-top: 0;
	}

	h1 {font-size: 1.2rem;}
	@media only screen and (min-width: 800px) {
		h1 {font-size: 1.5rem;}
	}
	
  h2 {
	  font-size: 1.1rem;
  }
	
	// Cascader
	.ant-cascader-menu {
		min-height: 360px;
	}
	
	// Modal
	.preview-modal {
		.ant-modal {
			top: 4vh;
			min-height: 700px;
			padding-left: .5rem;
			padding-right: .5rem;
			width: 100% !important;
			max-width: 1200px;
			.ant-modal-title {
				font-size: 1.4rem;
			}
			.ant-modal-body {
				padding: 0;
				font-size: 0;
			}
		}
	}
	
	// dl
	dt {font-size: 80%; font-weight: 300}
	
	// horizontal menu
	.ant-menu.ant-menu-horizontal {
		display: flex;
		margin-bottom: 1.75rem;
		.ant-menu-item {
			flex-grow: 1;
			text-align: center;
			font-size: 1.2rem;
			&.ant-menu-item-selected {
				a {color: black; font-weight: bold}
			}
		}
	}
	
	// Drawer
	.ant-drawer-body {
		padding: 1.25rem .75rem;
	}
	
	// Tree
	.ant-select {margin-bottom: 1rem}
	.ant-select-tree-dropdown {
		max-height: 270px !important;
		//overflow: auto;
		.ant-select-tree {
			span {white-space: normal;}
		}
	}
	.ant-select-tree li ul {
		padding-left: 10px;
	}
	
	// Result
	.ant-result {
		.ant-result-title {
			margin-bottom: 1rem;
		}
	}
	
`;

export default GlobalStyle;
