import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setCurrentUser } from './redux/user/user.actions';
import GlobalStyle from './globalStyle';
import Sidebar from './components/Sidebar';
import { Layout } from 'antd';
import Loader from './components/Loader';
import { auth, firestore } from './firebase';
import PreviewModal from './components/PreviewModal';
import MobileDrawer from './components/MobileDrawer';
import NotFound from './pages/NotFound'
import { fetchMaterials } from './utils/fetchMaterials';
import { updateMaterials, updateMaterialsNumber } from './redux/materials/materials.actions';
import { fetchMaterialsNumber } from './utils/fetchMaterialsNumber';

import Home from './pages/Home';
import Contact from './pages/Contact';
import Reset from './pages/Reset';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Order from './pages/Order';
import Help from './pages/Help';
import Material from './pages/Material';
import TestLinks from './pages/TestLinks';


const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged(user => {
      if (user) {
        const userRef = firestore.doc(`users/${user.uid}`);
        userRef.onSnapshot(snap => {
          dispatch(setCurrentUser({ id: snap.id, ...snap.data(), }));
        });
      }
      if (!user) {
        dispatch(setCurrentUser(null));
      }

    })
    return () => unsubscribeFromAuth()
  }, [dispatch]);

  useEffect(() => {
    fetchMaterials(5000)
      .then(materials => {
        const publishedMaterials = materials.filter(m => !m.isHidden);
        dispatch(updateMaterials(publishedMaterials));
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchMaterialsNumber()
      .then(total => dispatch(updateMaterialsNumber(total)))
  }, [dispatch]);

  return (
    <BrowserRouter>
      <GlobalStyle />
      {loading
        ? <Loader/>
        : <Layout style={{ minHeight: '101vh' }}>
          <Layout style={{ backgroundColor: 'white' }}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/contact" component={Contact} />
              <Route path="/order" component={Order} />
              <Route path="/signin" component={Signin} />
              <Route path="/signup" component={Signup} />
              <Route path="/help" component={Help} />
              <Route path="/reset" component={Reset} />
              <Route path="/m/:id" component={Material} />
              <Route path="/links" component={TestLinks} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
          <Sidebar />
        </Layout>
      }
      <PreviewModal />
      <MobileDrawer />
    </BrowserRouter>
  )
}


export default App;
