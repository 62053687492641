import React from 'react';
import styled from "styled-components";

const Video = props => {
  return (
    <Wrap>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${props.videoId}?rel=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video"
      />
    </Wrap>
  );
};

export default Video;

const Wrap = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  border: 1px solid #eee;
  margin-bottom: 1.5rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
