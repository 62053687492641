import {createSelector} from 'reselect'

const selectMaterials = state => state.materials

export const selectAllMaterials = createSelector(
	[selectMaterials],
	materials => materials.materials
);

export const selectFilteredMaterials = createSelector(
	[selectMaterials],
	materials => materials.materialsFiltered
);

export const selectMaterialsNumber = createSelector(
	[selectMaterials],
	materials => materials.materialsNumber
);

export const selectRubrics = createSelector(
	[selectMaterials],
	materials => materials.rubrics
);

export const selectPreviewModal = createSelector(
	[selectMaterials],
	materials => materials.previewModal
);
