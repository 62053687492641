import React from 'react';
import Container from "../components/Container";
import {Empty} from "antd";
import {Link} from "react-router-dom";

const NotFound = () => (
  <Container>
    <Empty description=""
           image={Empty.PRESENTED_IMAGE_SIMPLE}
           style={{padding: '20vh 20px'}}
    >
      <h1>404</h1>
      <br/>
      <p>Увы, такой страницы нет</p>
      <p><Link to="/">Вернуться на главную</Link></p>
    </Empty>

  </Container>
)

export default NotFound;
