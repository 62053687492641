import {notification} from "antd";

const openNotification = (text) => {
	notification.open({
		message: 'Ошибка',
		description: text,
		style: {
			backgroundColor: "#ff224e",
			color: 'white',
			fontWeight: 600,
		},
		duration: 8,
	});
};

export default openNotification;
