import React from 'react';
import {withRouter} from "react-router-dom";
import {Button, Tooltip} from 'antd';
import {ArrowLeftOutlined} from "@ant-design/icons";

const BackButton = ({history}) => {
    const hasBack = history.length > 2;
    return (
        <Tooltip placement="bottomLeft" title={hasBack ? 'Назад' : 'На главную'}>
            <Button onClick={() => hasBack ? history.goBack() : history.push('/')} type="link">
              <ArrowLeftOutlined/>
            </Button>
        </Tooltip>
    )
}

export default withRouter(BackButton);