import React from 'react';
import styled from 'styled-components'
import BackButton from "./BackButton"
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";
import {
  selectFilteredMaterials,
  selectAllMaterials,
  selectMaterialsNumber
} from "../redux/materials/materials.selectors";

const PageHeading = ({materials, allMaterials, materialsNumber}) => {
  const onInit = allMaterials.length === materials.length;
  const title = onInit ? "Электронная библиотека еврейского образования" : "Найденные материалы";

  return (
    <React.Fragment>
      <PageHeaderWrapper>

        <PageHeaderWrapperInner>
          {!onInit && <BackButton/>}
          <h1>{title}&nbsp;{onInit && <small>{materialsNumber}</small>}</h1>
        </PageHeaderWrapperInner>

      </PageHeaderWrapper>

      {onInit && <p style={{color: '#999'}}>Последние поступления</p>}

    </React.Fragment>
  )

}

const mapStateToProps = createStructuredSelector({
  materials: selectFilteredMaterials,
  allMaterials: selectAllMaterials,
  materialsNumber: selectMaterialsNumber,
});

export default connect(mapStateToProps)(PageHeading);

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;

  small {
    padding-left: .5rem;
    color: #ccc;
  }
`;

const PageHeaderWrapperInner = styled.div`
  display: flex;
  line-height: 1.2;
`;
