import React from 'react';
import styled from "styled-components";
import icon from "../../img/icon-archive.svg"

const Archive = () => <Wrap><img src={icon} alt="Архив"/></Wrap>;

export default Archive;

const Wrap = styled.div`
	position: relative;
	width: 100%;
	height: 50vh;
	background-color: #ddd;
	border-radius: 4px;
	margin-bottom: 1.5rem;
	img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
`;
