import React from 'react';
import styled from 'styled-components';
import Header from "./Header";

const ContainerWithHeader = ({children}) => (
  <React.Fragment>
    <Header/>
    <Wrap>{children}</Wrap>
  </React.Fragment>
)

export default ContainerWithHeader

const Wrap = styled.div`
  width: 100%;
  padding: .75rem 3% 6rem;
  @media only screen and (min-width: 700px) {
    padding: 1.5rem 4.5%;
  }
  @media only screen and (min-width: 1600px) {
    padding: 1.5rem 15%;
  }
`;
