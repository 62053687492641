import React from 'react';
import {Link} from "react-router-dom";
import styled from 'styled-components';
import logo from '../img/darkeinu-logo-white.svg'
import {Layout, Popconfirm, Space} from 'antd';
import {UserOutlined, LogoutOutlined, QuestionCircleOutlined, MailOutlined, CarryOutOutlined} from '@ant-design/icons';
import {auth} from '../firebase/index'
import {connect} from 'react-redux'
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../redux/user/user.selectors";

const {Sider} = Layout;

const Sidebar = ({currentUser}) => {
  return (
    <Wrap width={150}>
      <Space
        direction="vertical"
        size={60}
        style={{position: "sticky", top: '1rem'}}
      >
        <LinkItem to="/" title="На главную">
          <img style={{width: '90%'}} src={logo} alt="Даркейну"/>
        </LinkItem>

        <LinkItem to="/order">
          <CarryOutOutlined/>
          Заказать материал
        </LinkItem>

        <LinkItem to="/contact">
          <MailOutlined/>
          Задать вопрос
        </LinkItem>

        <LinkItem to="/help">
          <QuestionCircleOutlined/>
          Как пользоваться библиотекой
        </LinkItem>

        {
          !currentUser &&
          <LinkItem to="/signin">
            <UserOutlined size={30}/>
            Войти
          </LinkItem>
        }
        {
          currentUser &&
          <Popconfirm
            title="Вы действительно хотите выйти?"
            onConfirm={() => auth.signOut()}
            okText="Выйти"
            cancelText="Отмена"
            placement="leftTop"
          >
            <LinkItem to="/">
              <LogoutOutlined/>
              Выйти
              <small style={{wordBreak: 'break-all'}}>{currentUser.email}</small>
            </LinkItem>
          </Popconfirm>
        }
      </Space>
    </Wrap>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Sidebar)

const Wrap = styled(Sider)`
  display: none;
  padding: 1rem;
  @media screen and (min-width: 992px) {
    display: flex;
  }

  span.anticon {
    font-size: 30px;
    margin-bottom: 1rem;
  }
`

const LinkItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  transition: opacity .3s;
  
  &:hover {
    color: #fff;
    opacity: .8;
  }
`
