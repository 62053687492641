const firebaseConfig = {
	apiKey: "AIzaSyCAla0hnthJBlwZ_fBziKJRhSaRc22ieEo",
	authDomain: "darkeinu-library-2019.firebaseapp.com",
	databaseURL: "https://darkeinu-library-2019.firebaseio.com",
	projectId: "darkeinu-library-2019",
	storageBucket: "",
	messagingSenderId: "672962897095",
	appId: "1:672962897095:web:3e0774033b867e59",
	measurementId: "G-R7S73CG299",
};

export default firebaseConfig;

