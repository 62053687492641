import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import {Spin} from 'antd';

const Doc = props => {
  const docLink = props.filesLinks.slice(-1)[0].link || props.filesLinks[0].url;
  const link = `https://docs.google.com/viewer?url=${encodeURIComponent(docLink)}&embedded=true`;
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(0);
  const isDownloadable = props.isApproved && !props.isDownloadBlocked;

  useEffect(() => {
    let interval;
    if (loading && counter <= 3) {
      interval = setInterval(() => {
        setCounter(counter + 1);
        console.log('ping #', counter);
        iframeRef.current.src = link;
      }, 3000);
    }
    if (!loading || counter > 3) {
      setLoading(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [loading, link, counter])


  return (
    <Spin spinning={loading}>
      <Wrap isDownloadable={isDownloadable}>
        <iframe
          ref={iframeRef}
          src={link}
          onLoad={() => setLoading(false)}
          frameBorder="0"
          title="Document"/>
      </Wrap>
    </Spin>
  );
};

export default Doc;

const Wrap = styled.div`
	position: relative;
	width: 100%;
	height: 80vh;
	border: 1px solid #eee;
	margin-bottom: 1.5rem;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		background-color: transparent;
		width: 100%;
		height: ${props => props.isDownloadable ? '0' : '100px'};
	}
`;
