import React from 'react';
import {Button, Col, Form, Input, Menu, Result, Row} from 'antd';
import Container from "../components/Container";
import {Link} from "react-router-dom";
import {auth} from '../firebase/index'
import openNotification from "../utils/notification";
import GoHomeButton from "../components/GoHomeButton";
import DocumentTitle from "react-document-title";

class ResetForm extends React.Component {
  state = {
    sent: false,
    email: '',
  }

  handleSubmit = async values => {
    try {
      await auth.sendPasswordResetEmail(values.email, values.password);
      this.setState({sent: true, email: values.email})
    } catch (e) {
      e.code === 'auth/user-not-found'
        ? openNotification('Такой адрес у нас не зарегистрирован. Укажите другой, пожалуйста')
        : openNotification(e.message);
    }
  };

  render() {
    const {sent, email} = this.state;
    return (
      <Container>
        <DocumentTitle title='Новый пароль'/>

        <Row type="flex" justify="center" align="middle">
          <Col xs={22} md={7}>
            <GoHomeButton/>
            <br/><br/><br/>

            <Menu mode="horizontal" selectedKeys={['signin']}>
              <Menu.Item key="signin" style={{pointerEvents: "none"}}>
                <Link to="/signin">
                  Новый пароль
                </Link>
              </Menu.Item>
            </Menu>

            {sent ?
              <Result
                status="success"
                subTitle={`Мы отправили письмо на почту ${email}. Пройдите по ссылке в письме и придумайте себе новый пароль`}
              />
              :
              <Form
                onFinish={this.handleSubmit}
              >
                <Form.Item
                  name='email'
                  rules={[{type: 'email', required: true, message: 'Укажите адрес почты'}]}
                >
                  <Input size="large" placeholder="Укажите свою почту"/>
                </Form.Item>

                <p>
                  <Link to="/signin">
                    Войти
                  </Link>
                </p>

                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Отправить
                  </Button>

                </Form.Item>
              </Form>
            }

          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetForm;
