export const setURLParams = (q, t, f) => {
    const searchParams = new URLSearchParams();

    if (q) {
        searchParams.set("q", q);
    }
    if (t) {
        searchParams.set("t", t);
    }
    if (f) {
        searchParams.set("f", f);
    }

    return searchParams.toString();
}