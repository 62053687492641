import React from 'react';
import {Button, Tooltip} from 'antd';
import {createStructuredSelector} from "reselect";
import {showPreview} from "../redux/materials/materials.actions";
import {connect} from "react-redux";
import {selectCurrentUser} from "../redux/user/user.selectors";
import {CloudDownloadOutlined, EyeOutlined} from "@ant-design/icons";

const QuickButtons = ({material, currentUser, dispatch}) => {

    return (
        <>
            {
                !currentUser && null
            }
            {
                material.fileType !== 'kit' &&
                material.fileType !== 'archive' &&
                <Tooltip placement="bottomLeft" title={`Быстрый просмотр`}>
                    <Button
                        onClick={() => dispatch(showPreview(material))}
                        size="large"
                        type="link"
                        icon={<EyeOutlined/>}
                    />
                </Tooltip>
            }
            {
                !material.isDownloadBlocked &&
                material.fileType !== 'link' &&
                material.fileType !== 'kit' &&
                material.fileType !== 'video' &&
                material.fileType !== 'videoLink' &&
                material.fileType !== 'images' &&
                currentUser && currentUser.isApproved &&
                <Tooltip placement="bottomLeft" title={`Скачать файл`}>
                    <Button
                        size="large"
                        type="link"
                        icon={<CloudDownloadOutlined/>}
                        href={material.filesLinks && material.filesLinks.slice(-1)[0].link}
                        target="_blank"
                    />
                </Tooltip>
            }
        </>
    )
};


const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(QuickButtons)