import {materialsActionTypes} from "./materials.types";
import {searchMaterial, filterMaterial} from "./materials.utils";

const INITIAL_STATE = {
  materials: null,
  materialsFiltered: null,
  previewModal: {
    status: false,
    title: '',
    fileType: '',
    videoId: '',
    videoLink: '',
    filesLinks: '',
    id: '',
    isDownloadBlocked: false
  },
  materialsNumber: null,
  rubrics: {themes: [], formats: [],},
};


const materialsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case materialsActionTypes.UPDATE_MATERIALS:
      return {
        ...state,
        materials: action.materials,
        materialsFiltered: action.materials,
      };
    case materialsActionTypes.UPDATE_MATERIALS_NUMBER:
      return {
        ...state,
        materialsNumber: action.materialsNumber,
      };
    case materialsActionTypes.SEARCH_MATERIALS:
      return {
        ...state,
        materialsFiltered: searchMaterial(state.materials, action.searchParam)
      };
    case materialsActionTypes.FILTER_MATERIALS:
      return {
        ...state,
        materialsFiltered: filterMaterial(state.materials, action.filters),
      };
    case materialsActionTypes.SHOW_PREVIEW:
      return {
        ...state,
        previewModal: {
          status: true,
          title: action.material.title,
          kitmatTitle: action.material.kitmatTitle,
          fileType: action.material.fileType,
          videoId: action.material.videoId,
          videoLink: action.material.videoLink,
          filesLinks: action.material.filesLinks,
          link: action.material.link,
          id: action.material.id,
          isDownloadBlocked: action.material.isDownloadBlocked,
        },
      };
    case materialsActionTypes.CLOSE_PREVIEW:
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          status: false,
        },
      };
    case materialsActionTypes.UPDATE_RUBRICS:
      return {
        ...state,
        rubrics: action.rubrics,
      };
    default:
      return state
  }
};

export default materialsReducer;
