import React from 'react';
import Container from "../components/Container";
import {Button, Col, Form, Input, Result, Row} from "antd";
import DocumentTitle from "react-document-title";
import axios from 'axios';
import openNotification from '../utils/notification';
import GoHomeButton from "../components/GoHomeButton";

const Contact = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);


  const sendData = (email, text) => {
    setLoading(true);

    const body = {
      email,
      text,
      formDataNameOrder: '["email", "text"]',
      formGoogleSheetName: 'responses',
    };

    let bodyEncoded = Object.keys(body).map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(body[k]);
    }).join('&');

    axios.post('https://script.google.com/macros/s/AKfycbxVMLdI9EaTqTH6tCC2uRAzAzkNh0yYcjrxw_Z_BwVN5ud687Q/exec', bodyEncoded)
      .then(() => {
        setLoading(false);
        setSent(true);
      })
      .catch(err => {
        setLoading(false);
        openNotification(err.message);
        console.error("Error when sending message: ", err);
      });
  };

  const onSubmit = values => {
    sendData(values.email, values.text);
  };

  return (
    <Container>
      <DocumentTitle title="Задать вопрос"/>

      <Row type="flex" justify="center" align="middle">
        <Col xs={24} md={12}>

          <GoHomeButton/>
          <br/><br/><br/>

          {!sent &&
          <>
            <h1>Задать вопрос</h1>
            <br/>
            <Form
              onFinish={onSubmit}
            >

              <Form.Item
                name="email"
                rules={[
                  {type: 'email', message: 'Неправильный адрес почты. Пример правильного: name@domain.com',},
                  {required: true, message: 'Укажите почту',},
                ]}
              >
                <Input size="large" placeholder="Адрес электронной почты, на который мы сможем вам ответить"/>
              </Form.Item>

              <Form.Item
                name="text"
                rules={[{required: true, message: 'Напишите сообщение'}]}
              >
                <Input.TextArea
                  placeholder="Текст сообщения" maxLength={1000} rows={14}
                />
              </Form.Item>

              <Button
                loading={loading}
                htmlType="submit"
                size="large"
                type="primary"
              >
                Отправить
              </Button>

            </Form>
          </>
          }

          {sent &&
          <Result
            status="success"
            title="Сообщение отправлено"
            subTitle="Рассмотрим ваше сообщение в ближайшее время. Спасибо."
            extra={[
              <Button key="home" size="large" type="primary" onClick={() => props.history.push('/')}>
                Перейти на главную
              </Button>,
            ]}
          />
          }

        </Col>
      </Row>
    </Container>
  )
};

export default Contact;
