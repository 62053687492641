import React, {useEffect, useState} from "react";
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {Cascader, TreeSelect} from "antd";
import {setURLParams} from "../utils/setURLParams";
import {getURLParams} from "../utils/getURLParams";
import {addFiltersParams, updateRubrics} from "../redux/materials/materials.actions";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectRubrics} from "../redux/materials/materials.selectors";
import {firestore} from "../firebase";
import {useMedia} from 'react-use';

const Filters = ({type, history, location, addFiltersParams, updateRubrics, rubrics}) => {
  const [formats, setFormats] = useState()
  const [themes, setThemes] = useState()
  const {q, f, t} = getURLParams(location);
  const isDesktop = useMedia('(min-width: 768px)');

  useEffect(() => {
    firestore
      .collection("rubrics")
      .get()
      .then(snap => {
        let rubrics = {};
        snap.forEach(doc => rubrics[doc.id] = doc.data().all);
        updateRubrics(rubrics);
      })
  }, [updateRubrics]);

  useEffect(() => {
    if (f.length === 0) {
      setFormats([])
    } else {
      setFormats(f.split(','))
    }
    if (t.length === 0) {
      setThemes([])
    } else {
      setThemes(t.split(','))
    }

    q.length === 0 && addFiltersParams({themes: t.split(','), formats: f.split(',')})

  }, [t, f, q, setThemes, setFormats, addFiltersParams])

  const onChange = (value) => {
    let url = '';

    if (type === 'themes') {
      setThemes(value)
      url = setURLParams(null, value, f);
    }
    if (type === 'formats') {
      setFormats(value);
      url = setURLParams(null, t, value);
    }

    return history.push(`/?${url}`)
  };

  return isDesktop ?
    <FiltersDesktop type={type}>
      <Cascader
        size='large'
        options={type === 'formats' ? rubrics.formats : rubrics.themes}
        placeholder={type === 'formats' ? 'Выбрать рамки' : 'Выбрать тематику'}
        expandTrigger='hover'
        onChange={value => onChange(value)}
        value={type === 'formats' ? formats : themes}
        changeOnSelect
        displayRender={label => label.join(' > ')}
      />
    </FiltersDesktop> :
    <FiltersMobile>
      <TreeSelect
        allowClear
        value={type === 'formats' ? formats : themes}
        treeData={type === 'formats' ? rubrics.formats : rubrics.themes}
        placeholder={type === 'formats' ? 'Выбрать рамки' : 'Выбрать тематику'}
        onChange={value => onChange(value)}
      />
    </FiltersMobile>
}

const mapStateToProps = createStructuredSelector({
  rubrics: selectRubrics,
})

const mapDispatchToProps = dispatch => ({
  addFiltersParams: filters => dispatch(addFiltersParams(filters)),
  updateRubrics: rubrics => dispatch(updateRubrics(rubrics)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filters))


const FiltersDesktop = styled.div`
  position: relative;

  &::after {
    content: ${props => props.type === 'themes' ? '"Тематика"' : '"Образовательные рамки"'};
    position: absolute;
    left: 10px;
    top: -10px;
    font-size: .8rem;
    background-color: white;
    padding: 0 2px;
    color: #bfbfbf;
    display: inline-block;
  }

  .ant-cascader-picker {
    width: 100%;
  }

  .ant-cascader-picker-label {
    color: blue;
  }

  .ant-cascader-picker-with-value .ant-cascader-picker-label {
    color: transparent;
  }
}
`

const FiltersMobile = styled.div`
  .ant-select {
    width: 100%;
    margin-bottom: .5rem;
  }
`

