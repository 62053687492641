import React, {memo} from 'react';
import DocumentTitle from 'react-document-title';
import BackButton from './BackButton';
import { Alert, Button, Col, Row } from 'antd';
import Video from './viewers/Video';
import Doc from './viewers/Doc';
import ImageViewer from './viewers/ImageViewer';
import Audio from './viewers/Audio';
import Archive from './viewers/Archive';
import VideoByLink from './viewers/VideoByLink';
import KitList from './KitList';
import LinkViewer from './viewers/LinkViewer';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import ContainerWithHeader from './ContainerWithHeader';

const MaterialBody = ({ material, currentUser }) => {
  const {
    title,
    description,
    fileType,
    videoId,
    filesLinks,
    keywords,
    materialType,
    author,
    source,
    createdAt,
    videoLink,
    related,
    link,
    isDownloadBlocked,
  } = material;

  return (
    <ContainerWithHeader>
      <DocumentTitle title={`${title} | Библиотека Даркейну`} />
      {fileType === 'kit' && <KitLabel>Комплект материалов</KitLabel>}
      <Heading>
        <BackButton />
        <h1>{title}</h1>
      </Heading>
      <Row gutter={24} type="flex">
        <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 19 }}>
          {fileType === 'video' &&
          <Video videoId={videoId} />
          }
          {(fileType === 'pdf' || fileType === 'doc' || fileType === 'ppt') &&
          <Doc filesLinks={filesLinks} isApproved={currentUser && currentUser.isApproved}
               isDownloadBlocked={isDownloadBlocked} />
          }
          {fileType === 'images' &&
          <ImageViewer filesLinks={filesLinks} />
          }
          {fileType === 'audio' &&
          <Audio filesLinks={filesLinks} />
          }
          {fileType === 'archive' &&
          <Archive filesLinks={filesLinks} />
          }
          {fileType === 'videoLink' &&
          <VideoByLink videoLink={videoLink} />
          }
          {fileType === 'kit' &&
          <KitList kitMaterials={material.kitMaterials} isDownloadBlocked={isDownloadBlocked} />
          }
          {fileType === 'link' &&
          <LinkViewer link={link} />
          }
        </Col>
        <Col xs={{ order: 1, span: 24 }} lg={{ order: 2, span: 5 }}>
          <TextWrap>
            {
              !isDownloadBlocked && fileType !== 'images' && fileType !== 'video' && fileType !== 'videoLink' &&
              fileType !== 'kit' && fileType !== 'link' && currentUser && currentUser.isApproved &&
              <Button
                href={filesLinks && filesLinks.length > 0 && filesLinks.slice(-1)[0].link}
                target="_blank"
                type="primary"
                size="large"
              >
                Скачать файл
              </Button>
            }
            {
              !isDownloadBlocked && fileType === 'videoLink' && currentUser && currentUser.isApproved &&
              <Button
                href={videoLink}
                target="_blank"
                type="primary"
                size="large"
              >
                Скачать файл
              </Button>
            }
            {
              isDownloadBlocked && currentUser && currentUser.isApproved &&
              <>
                <Alert
                  type="info"
                  message={
                    <div style={{ fontSize: 12 }}>
                      {fileType === 'kit' ? `Материалы комплекта недоступны для скачивания` : `Материал недоступен для скачивания`}

                    </div>
                  }
                />
                <br />
              </>
            }
            {
              fileType !== 'images' && fileType !== 'video' && !currentUser &&
              <>
                <Alert
                  type="info"
                  message={
                    <div style={{ fontSize: 12 }}>
                      Скачивать материалы библиотеки могут только авторизованные
                      пользователи. <Link to="/signin">Войти</Link>
                    </div>
                  }
                />
                <br />
              </>}

            {
              fileType !== 'images' && fileType !== 'video' && currentUser && !currentUser.isApproved &&
              <>
                <Alert
                  type="info"
                  message={
                    <div style={{ fontSize: 12 }}>
                      Вы зарегистрировались, но пока не&nbsp;получили доступ к&nbsp;скачиванию
                      материалов. Ваша заявка рассматривается. <Link to="/contact">Написать нам</Link>
                    </div>
                  }
                />
                <br />
              </>}

            {
              related && related.length > 0 &&
              <dl>
                <dt style={{ marginBottom: 6 }}>Связанные материалы</dt>
                {related.map((item, i) => {
                  const linkObj = new URL(item.link);
                  const isInnerLink = linkObj.hostname === ('darkeinu-library.org');
                  return (
                    <dd key={i}>
                      {i + 1}.{' '}{isInnerLink
                      ? <Link to={linkObj.pathname}>{item.title}</Link>
                      : <a href={linkObj.href} target="_blank" rel="noopener noreferrer">{item.title}</a>
                    }
                    </dd>
                  )
                })}
              </dl>
            }

            {description &&
            <dl>
              <dt>Содержание</dt>
              <dd>{description}</dd>
            </dl>
            }
            {materialType &&
            <dl>
              <dt>Вид материала</dt>
              <dd>{materialType}</dd>
            </dl>
            }
            {keywords &&
            <dl>
              <dt>Ключевые слова</dt>
              <dd>{keywords.join(', ')}</dd>
            </dl>
            }
            {source &&
            <dl>
              <dt>Источник</dt>
              <dd>{source}</dd>
            </dl>
            }
            {author &&
            <dl>
              <dt>Автор</dt>
              <dd>{author}</dd>
            </dl>
            }
            <dl>
              <dt>Дата</dt>
              <dd style={{ textTransform: 'capitalize' }}>
                {dayjs(createdAt).format('MMMM YYYY')}
              </dd>
            </dl>
          </TextWrap>
        </Col>
      </Row>
    </ContainerWithHeader>
  );
};

export default memo(MaterialBody);

const Heading = styled.div`
  display: flex;
  margin-bottom: 1rem;

  button {
    padding-left: 0;
  }
`;

const TextWrap = styled.div`
  .ant-btn {
    margin-bottom: 1.5rem;
  }

  overflow-wrap: break-word;
`;

const KitLabel = styled.div`
  color: #999;
  font-size: 90%;
  padding-left: 30px;
`
