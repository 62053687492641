import React from 'react';
import styled from 'styled-components';

const Container = ({children}) => <Wrap>{children}</Wrap>

export default Container;

const Wrap = styled.div`
  width: 100%;
  padding: .75rem 3% 6rem;
  @media only screen and (min-width: 700px) {
    padding: 1.5rem 4.5%;
  }
  @media only screen and (min-width: 1600px) {
    padding: 1.5rem 15%;
  }
`;
