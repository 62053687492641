export const searchMaterial = (materials, searchParams) => {
    if (searchParams.length === 0) {
        return materials;
    }

    return materials.filter(material => {
        let title = material.title.toLowerCase();
        let keywords = material.keywords && material.keywords.join('').toLowerCase();
        let materialType = material.materialType.toLowerCase();
        let target = searchParams.trim().toLowerCase();

        return (title.indexOf(target) !== -1 || materialType.indexOf(target) !== -1 || (keywords && keywords.indexOf(target) !== -1))
    })
};

export const filterMaterial = (materials, filtersParams) => {

    if (filtersParams.themes[0].length === 0 && filtersParams.formats[0].length === 0) {
        return materials;
    }

    let filters = [];
    let materialsToShow = [];
    let AllSelectedMaterials = [];

    filters = [...filtersParams.themes, ...filtersParams.formats];
    filters = filters.filter(item => item.length > 0)

    filters.forEach(filter => {
        materials.forEach(material => {
            let materialTags = [...material.themes, ...material.formats];
            if (materialTags.includes(filter)) {
                AllSelectedMaterials.push(material)
            }
        })
    });

    // if AllSelectedMaterials has duplicates by filters-times show these duplicates
    // TODO make this simpler, another solution https://jsfiddle.net/Predve4niy/6gh833k9/
    let counter = AllSelectedMaterials.reduce((o, i) => {
        if (!o.hasOwnProperty(i.id)) {
            o[i.id] = 0;
        }
        o[i.id]++;
        return o;
    }, {});
    let results = Object.keys(counter).filter(id => counter[id] === filters.length);

    results.forEach(result => {
        materials.forEach(material => {
            if (material.id === result) {
                materialsToShow.push(material)
            }
        })
    });

    return materialsToShow;

}