import React from 'react';
import ContainerWithHeader from '../components/ContainerWithHeader';
import MaterialsList from '../components/MaterialsList';
import PageHeading from '../components/PageHeading';
import DocumentTitle from 'react-document-title';

const Home = () => {
  return (
    <ContainerWithHeader>
      <DocumentTitle title="Электронная библиотека еврейского образования Даркейну" />
      <PageHeading />
      <MaterialsList />
    </ContainerWithHeader>
  )
}

export default Home;
