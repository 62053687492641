import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Col, Row} from 'antd';
import QuickButtons from './QuickButtons';
import {checkFileType} from '../utils/checkFileTypes';
import {listFormats} from '../utils/listFormats';

const MaterialsListItem = ({material}) => {
  const {title, formats, id, description, fileType} = material;
  return (
    <RowWrap>
      <Row type="flex" align="middle">
        <Col xs={3} md={1}>
          <Link to={`/m/${id}`}>
            <img src={checkFileType(fileType)} width={32} alt=""/>
          </Link>
        </Col>
        <Col xs={21} md={9}>
          <Title>
            <Link to={`/m/${id}`}>
              {title}<Formats>{listFormats(formats)}</Formats>
            </Link>
          </Title>
        </Col>
        <Col xs={0} md={12}>
          <Descr>
            {description.length > 200 ? `${description.slice(0, 200)}...` : description}
          </Descr>
        </Col>
        <Col xs={0} md={2}>
          <Controls>
            <QuickButtons material={material}/>
          </Controls>
        </Col>
      </Row>
    </RowWrap>
  );
};

export default MaterialsListItem;

const Formats = styled.span`
  color: #373737;
  font-weight: 300;
`;

const Title = styled.h2`
  a {
    color: #373737;
    font-size: 1rem;
  }

  @media only screen and (min-width: 992px) {
    padding-right: 1rem;
  }
`;

const Descr = styled.div`
  overflow-wrap: break-word;
`

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const RowWrap = styled.div`
  width: 100%;
  padding: .75rem;
  border-top: 1px solid rgba(0, 0, 0, .05);
  background-color: #fefefe;

  p {
    color: #afafaf;
    margin-bottom: 0;
    padding: 0 1rem;
  }

  img {
    border-radius: 50%;
    width: 24px
  }
  
  @media screen and (min-width: 1050px) {
    img {
      width: 32px
    }
  }
`;
