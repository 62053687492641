import {firestore} from "../firebase";

export const fetchMaterialsNumber = async () => {
  try {
    const totalRef = await firestore.collection('materialsNumber').doc('total')
    const doc = await totalRef.get();
    return doc.data().value;
  } catch (error) {
    console.log("Error getting total:", error)
  }

};