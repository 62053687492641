import React from 'react';
import {Button} from "antd";
import {Link} from "react-router-dom";
import {LeftOutlined} from "@ant-design/icons";

const GoHomeButton = () => (
  <Button
    type="link"
    style={{paddingLeft: 0}}
  >
    <Link to="/">
      <LeftOutlined/>&nbsp;&nbsp;
      Библиотека Даркейну
    </Link>
  </Button>
)

export default GoHomeButton;