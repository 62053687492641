import React from 'react';
import styled from "styled-components";

const Audio = props => {
	const link = props.filesLinks.slice(-1)[0].link || props.filesLinks[0].url;
	return (
		<Wrap>
			<audio controls controlsList="nodownload">
				<source src={link} type="audio/mpeg"/>
				Ваш браузер не поддерживает проигрывание аудиозаписей. Воспользуйтесь современным браузером.
			</audio>
		</Wrap>
	);
}

export default Audio;

const Wrap = styled.div`
	width: 100%;
	height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fafafa;
	margin-bottom: 1.5rem;
	audio {
    max-width: 100%;
    width: 90%;
    outline: none;
	}
`;
