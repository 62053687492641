import React from 'react';
import styled from "styled-components";
import {Button, Col, Row, Tooltip} from "antd";
import {checkFileType} from "../utils/checkFileTypes";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../redux/user/user.selectors";
import {connect} from "react-redux";
import {showPreview} from "../redux/materials/materials.actions";
import {CloudDownloadOutlined, EyeOutlined} from "@ant-design/icons";

const KitList = ({kitMaterials, isDownloadBlocked, currentUser, dispatch}) => {

    return (
        <Wrap>
            {kitMaterials.map((item, i) =>
                <Item key={i}>
                    <Row type="flex" align="middle">
                        <Col xs={3} md={2} align="center">
                            <img src={checkFileType(item.fileType)} alt=""/>
                        </Col>
                        <Col xs={19} md={20}>
                            <Title onClick={() => dispatch(showPreview({...item, isDownloadBlocked}))}>
                                {item.kitmatTitle}
                            </Title>
                        </Col>
                        <Col xs={2}>
                            <Controls>
                                {
                                    item.fileType !== 'archive' &&
                                    <Tooltip placement="bottomLeft" title={`Быстрый просмотр`}>
                                        <Button
                                            onClick={() => dispatch(showPreview({...item, isDownloadBlocked}))}
                                            size="large"
                                            type="link"
                                            icon={<EyeOutlined/>}
                                        />
                                    </Tooltip>
                                }
                                {
                                    !isDownloadBlocked &&
                                    item.fileType !== 'video' &&
                                    item.fileType !== 'videoLink' &&
                                    item.fileType !== 'images' &&
                                    item.fileType !== 'link' &&
                                    currentUser && currentUser.isApproved &&
                                    <Tooltip placement="bottomLeft" title={`Скачать файл`}>
                                        <Button
                                            size="large"
                                            type="link"
                                            icon={<CloudDownloadOutlined/>}
                                            href={item.filesLinks && item.filesLinks[0].url}
                                            target="_blank"
                                        />
                                    </Tooltip>
                                }
                            </Controls>
                        </Col>
                    </Row>
                </Item>
            )}
        </Wrap>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(KitList)

const Wrap = styled.div`
  @media only screen and (min-width: 992px) {
		padding-right: 5rem;
	}
`

const Item = styled.div`
  background: white;
  box-shadow: 0 4px 21px 0 rgba(0,0,0,0.04);
  border-radius: 8px;
  padding: 1rem .5rem;
  margin-bottom: 1.5rem;
  img {border-radius: 50%; width: 20px;}
  @media only screen and (min-width: 992px) {
     padding: 1rem;   
     img {border-radius: 50%; width: 30px;}
    }
`

const Title = styled.h2`
  font-size: 1rem;
  color: #373737;
  cursor: pointer;
  transition: color .3s;
  &:hover {color: #1890ff;}
  display: inline-block;
    @media only screen and (min-width: 992px) {
      padding-right: 1rem;
    }
`;

const Controls = styled.div`
	display: flex;
	justify-content: flex-end;
`;