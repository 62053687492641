import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { firestore } from '../firebase';
import MaterialBody from '../components/MaterialBody';
import { setURLParams } from '../utils/setURLParams';

const Material = ({ history }) => {
  const { id } = useParams();
  const { currentUser } = useSelector(state => state.user);
  const [material, setMaterial] = useState(null);

  useEffect(() => {
    firestore
      .doc(`materials/${id}`).get()
      .then(doc => {
        if (!doc.exists) return history.push('/404');
        setMaterial(doc.data());
      }).catch(e => alert(`Ошибка: ${e.message}`));
  }, [id, history])

  useEffect(() => {
    const url = setURLParams(null, material?.themes, material?.formats);
    history.replace(`?${url}`)
  }, [material, history])


  return (material &&
    <MaterialBody
      material={material}
      currentUser={currentUser}
    />
  )
};

export default Material;


