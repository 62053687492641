import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const Item = ({material, i}) =>
  <p>
    {i + 1}.&nbsp;
    <Link to={`/m/${material.id}`}>
      {material.title}
    </Link>
  </p>

const TestLinks = () => {
  const {materials} = useSelector(state => state.materials);
  console.log('materials', materials);

  const links = materials.filter((m => m.fileType === 'link'));
  const youtubes = materials.filter((m => m.fileType === 'video'));
  const sharepoints = materials.filter((m => m.fileType === 'videoLink'));
  const kits = materials
    .filter((m => m.fileType === 'kit'))
    .filter(m => m.kitMaterials.some(m => m.fileType === 'link' ||  m.fileType === 'video' ||  m.fileType === 'videoLink'))

  console.log('kits', kits)


  return (
    <div style={{padding: '5rem'}}>
      <h1>Ссылки</h1><br/>
      {links.map((m, i) => <Item material={m} key={m.id} i={i}/>)}
      <br/>
      <br/>
      <h1>Видео на ютюбе</h1><br/>
      {youtubes.map((m, i) => <Item material={m} key={m.id} i={i}/>)}
      <br/>
      <br/>
      <h1>Видео в шерпоинте</h1><br/>
      {sharepoints.map((m, i) => <Item material={m} key={m.id} i={i}/>)}
      <br/>
      <br/>
      <h1>Комплекты, в которых есть материалы со ссылкой, ютюбом или видео из шерпоинта</h1><br/>
      {kits.map((m, i) => <Item material={m} key={m.id} i={i}/>)}
      <br/>
      <br/>
    </div>
  );
};

export default TestLinks;