import {materialsActionTypes} from './materials.types'

export const updateMaterials = materials => ({
    type: materialsActionTypes.UPDATE_MATERIALS,
    materials,
});

export const updateMaterialsNumber = materialsNumber => ({
    type: materialsActionTypes.UPDATE_MATERIALS_NUMBER,
    materialsNumber,
});

export const addSearchParam = searchParam => ({
    type: materialsActionTypes.SEARCH_MATERIALS,
    searchParam,
});

export const addFiltersParams = filters => ({
    type: materialsActionTypes.FILTER_MATERIALS,
    filters,
});

export const toggleView = () => ({
    type: materialsActionTypes.TOGGLE_VIEW,
});

export const showPreview = material => ({
    type: materialsActionTypes.SHOW_PREVIEW,
    material,
});

export const closePreview = () => ({
    type: materialsActionTypes.CLOSE_PREVIEW
});

export const updateRubrics = rubrics => ({
    type: materialsActionTypes.UPDATE_RUBRICS,
    rubrics,
});
