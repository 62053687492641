import React from 'react';
import {Button, Col, Form, Input, Menu, Row} from 'antd';
import Container from "../components/Container";
import {Link} from "react-router-dom";
import {auth} from "../firebase/index";
import openNotification from "../utils/notification";
import GoHomeButton from "../components/GoHomeButton";
import DocumentTitle from "react-document-title";

class SigninForm extends React.Component {

  onSubmit = async values => {
    try {
      await auth.signInWithEmailAndPassword(values.email, values.password);
      this.props.history.push('/');
    } catch (e) {
      if (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found') {
        openNotification('Неправильный логин или пароль');
      } else {
        openNotification(e.message);
      }
    }
  };

  render() {
    return (
      <Container>
        <DocumentTitle title='Вход'/>

        <Row type="flex" justify="center" align="middle">
          <Col xs={22} md={7}>
            <GoHomeButton/>
            <br/><br/><br/>

            <Menu mode="horizontal" selectedKeys={['signin']}>
              <Menu.Item key="signin">
                <Link to="/signin">
                  Вход
                </Link>
              </Menu.Item>
              <Menu.Item key="signup">
                <Link to="/signup">
                  Регистрация
                </Link>
              </Menu.Item>
            </Menu>

            <Form onFinish={this.onSubmit}>
              <Form.Item
                name='email'
                rules={[{type: 'email', required: true, message: 'Укажите правильный адрес почты'}]}
              >
                <Input size="large" placeholder="Почта"/>
              </Form.Item>
              <Form.Item
                name='password'
                rules={[{required: true, message: 'Укажите пароль'}]}
              >
                <Input.Password size="large" placeholder="Пароль"/>
              </Form.Item>

              <p>
                <Link to="/reset">
                  Забыли пароль?
                </Link>
              </p>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Войти
                </Button>
              </Form.Item>

            </Form>

          </Col>
        </Row>
      </Container>
    );
  }
}

export default SigninForm;
