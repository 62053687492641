import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import {Input} from 'antd';
import logosm from '../img/darkeinu-logo-sm.svg'
import {connect} from "react-redux";
import {addSearchParam} from "../redux/materials/materials.actions";
import {setURLParams} from "../utils/setURLParams";
import {getURLParams} from "../utils/getURLParams";

const {Search} = Input;

const SearchBar = ({history, location, addSearchParam}) => {

    const [searchValue, setSearchValue] = useState('')
    const {q} = getURLParams(location);

    useEffect(() => {
        setSearchValue(q);
        addSearchParam(q);
    }, [q, addSearchParam, setSearchValue])

    const onSubmit = (value) => {
        const url = setURLParams(searchValue, null, null);
        history.push(`/?${url}`)
        addSearchParam(value)
    };

    return (
        <Wrap>
            <MobileLogo to="/">
                <img width={60} src={logosm} alt=""/>
            </MobileLogo>
            <Search
                placeholder="Укажите название материала или ключевые слова"
                enterButton="Найти"
                size="large"
                allowClear
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                onSearch={value => onSubmit(value)}
            />
        </Wrap>
    )
}

const mapDispatchToProps = dispatch => ({
    addSearchParam: value => dispatch(addSearchParam(value))
})

export default withRouter(connect(null, mapDispatchToProps)(SearchBar))

const Wrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	a {
		font-size: 0;
		margin-right: 1rem;
	}
`;

const MobileLogo = styled(Link)`
    @media screen and (min-width: 992px) {
      display: none;
    }
`