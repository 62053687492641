import React from 'react';
import styled from "styled-components";

const VideoByLink = ({videoLink}) => {
	return (
		<Wrap>
			<video controls controlsList="nodownload">
				<source src={videoLink}/>
						Ваш браузер не поддерживает видео. Пользуйтесь современными браузерами, например, Chrome или Firefox.
			</video>
		</Wrap>
	);
};

export default VideoByLink;

const Wrap = styled.div`
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	margin-bottom: 1.5rem;
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;
