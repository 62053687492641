export const materialsActionTypes = {
    UPDATE_MATERIALS: 'UPDATE_MATERIALS',
    UPDATE_MATERIALS_NUMBER: 'UPDATE_MATERIALS_NUMBER',
    SEARCH_MATERIALS: 'SEARCH_MATERIALS',
    FILTER_MATERIALS: 'FILTER_MATERIALS',
    //FILTER_MATERIALS_BY_THEMES: 'FILTER_MATERIALS_BY_THEMES',
    //FILTER_MATERIALS_BY_FORMATS: 'FILTER_MATERIALS_BY_FORMATS',
    TOGGLE_VIEW: 'TOGGLE_VIEW',
    SHOW_PREVIEW: 'SHOW_PREVIEW',
    CLOSE_PREVIEW: 'CLOSE_PREVIEW',
    UPDATE_RUBRICS: 'UPDATE_RUBRICS',
}