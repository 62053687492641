import React from 'react';
import {Button, Col, Form, Input, Menu, Result, Row} from 'antd';
import Container from "../components/Container";
import {Link} from "react-router-dom";
import {auth, saveUserToFirestore} from "../firebase/index";
import openNotification from "../utils/notification";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../redux/user/user.selectors";
import {connect} from "react-redux";
import axios from "axios";
import {CheckCircleOutlined, SyncOutlined} from "@ant-design/icons";
import GoHomeButton from "../components/GoHomeButton";
import DocumentTitle from "react-document-title";

class SignupForm extends React.Component {

  triggerGoogleMailer = (userData) => {
    const body = {
      email: userData.email,
      name: userData.name,
      org: userData.organisation,
      city: userData.city,
      formDataNameOrder: '["name", "city", "org", "email"]',
      formGoogleSheetName: 'responses',
    };

    let bodyEncoded = Object.keys(body).map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(body[k]);
    }).join('&');

    axios.post('https://script.google.com/macros/s/AKfycbzK22wsXcdAdfLEboNgMQOgMmIyr335S0Ay6WPbysB6E3g53t9k/exec', bodyEncoded)
      .catch(err => {
        openNotification(err.message);
        console.error("Error on triggering Google Mailer: ", err);
      });
  }

  onSubmit = async values => {
    const userData = {
      email: values.email,
      name: values.name,
      organisation: values.organisation,
      city: values.city,
      createdAt: Date.now(),
      isApproved: false,
      isModerator: false,
      isAdmin: false,
    }

    try {
      const {user} = await auth.createUserWithEmailAndPassword(values.email, values.password);
      await saveUserToFirestore(user, userData);
      await this.triggerGoogleMailer(userData);
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        openNotification('Такой адрес уже зарегистрирован');
      } else {
        openNotification(e.message);
      }
    }
  };

  render() {
    const {currentUser} = this.props;
    return (
      <Container>
        <DocumentTitle title='Регистрация'/>

        <Row type="flex" justify="center" align="middle">
          <Col xs={22} md={7}>
            <GoHomeButton/>
            <br/><br/><br/>

            <Menu mode="horizontal" selectedKeys={['signup']}>
              <Menu.Item key="signin">
                <Link to="/signin">
                  Вход
                </Link>
              </Menu.Item>
              <Menu.Item key="signup">
                <Link to="/signup">
                  Регистрация
                </Link>
              </Menu.Item>
            </Menu>

            {
              !currentUser &&
              <Form onFinish={this.onSubmit}>
                <Form.Item
                  name="name"
                  rules={[{
                    type: 'string',
                    max: 100,
                    required: true,
                    message: 'Укажите свое имя и фамилию'
                  }]}
                >
                  <Input size="large" placeholder="Имя и фамилия"/>
                </Form.Item>
                <Form.Item
                  name="city"
                  rules={[{type: 'string', max: 100, required: true, message: 'Укажите город'}]}
                >
                  <Input size="large" placeholder="Город"/>
                </Form.Item>
                <Form.Item
                  name="organisation"
                  rules={[{
                    type: 'string',
                    max: 100,
                    required: true,
                    message: 'Укажите организацию'
                  }]}
                >
                  <Input size="large" placeholder="Организация"/>
                </Form.Item>
                <Form.Item
                  name='email'
                  rules={[{
                    type: 'email',
                    max: 100,
                    required: true,
                    message: 'Укажите правильный адрес почты'
                  }]}
                >
                  <Input size="large" placeholder="Почта"/>
                </Form.Item>
                <Form.Item
                  name='password'
                  rules={[{max: 100, min: 6, required: true, message: 'Минимум 6 символов'}]}
                >
                  <Input.Password size="large" placeholder="Пароль" autoComplete="new-password"/>
                </Form.Item>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Отправить
                  </Button>
                </Form.Item>
              </Form>
            }

            {
              currentUser && currentUser.isApproved &&
              <Result
                icon={<CheckCircleOutlined/>}
                title={<h2>Вы уже зарегистрированы</h2>}
              />
            }

            {
              currentUser && !currentUser.isApproved &&
              <Result
                icon={<SyncOutlined/>}
                title={<h2>Спасибо! Мы&nbsp;рассмотрим вашу заявку</h2>}
                subTitle="Когда мы одобрим заявку, вы получите полный доступ к&nbsp;материалам библиотеки"
                extra={<Link to="/contact" type="">Написать нам</Link>}
              />
            }

          </Col>
        </Row>
      </Container>

    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(SignupForm)
