import React from 'react';
import styled from "styled-components";
import placeholder from "../../img/link-placeholder.svg"

const LinkViewer = ({link}) => {
    return (
        <Wrap href={link} target="_blank">
            <img src={placeholder} alt="Перейти к учебному материалу ›"/>
        </Wrap>
    );
};

export default LinkViewer;

const Wrap = styled.a`
	box-shadow: 0 4px 10px rgba(0,0,0,.1);
	border-radius: 4px;
	margin-bottom: 1.5rem;
	display: block;
	overflow: hidden;
	text-align: center;
	img {
		max-width: 100%;
		transition: transform .5s;
		:hover {
			transform: scale(1.05);
		}
	}
`;
