import React from 'react';
import styled from "styled-components";
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {ButtonBack, ButtonNext, CarouselProvider, Image, Slide, Slider} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const ImageViewer = props => {
	const images = props.filesLinks.slice(-10);
	return (
		<Wrap>
			<CarouselProvider
				naturalSlideWidth={20}
				naturalSlideHeight={1}
				totalSlides={images.length}
			>
				<ButtonBack className="prev"><LeftOutlined /></ButtonBack>
				<ButtonNext className="next"><RightOutlined /></ButtonNext>
				<Slider>
					{images.map((img, i) =>
						<Slide className="slide" key={i} index={i}>
							<Image hasMasterSpinner src={img.link || img.url} alt=""/>
						</Slide>
					)}
				</Slider>
			</CarouselProvider>
		</Wrap>
	);
};

export default ImageViewer;

const Wrap = styled.div`
	background-color: #fafafa;
	position: relative;
	.prev, .next {
		position: absolute;
		top: calc(50% - 15px);
		z-index: 1;
		border: none;
		color: white;
		background-color: rgba(0,0,0,.2);
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		opacity: 0;
		transition: opacity .4s;
	}
	.prev {
		left: .5rem;
	}
	.next {
		right: .5rem;
	}
	&:hover {.prev, .next {opacity: 1;}}
	.slide {
	height: 50vh;
		@media screen and (min-width: 700px) {
			height: 80vh;
		}
		img {
	    object-fit: contain;
		}
	}
`;
