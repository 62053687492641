import React from 'react';
import MaterialsListItem from './MaterialsListItem'
import {createStructuredSelector} from "reselect";
import {selectFilteredMaterials} from "../redux/materials/materials.selectors";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

const MaterialsList = ({materials}) => {
  const {pathname, search} = useLocation();
  const isHome = pathname === '/' && search === '';
  const amount = isHome ? 20 : 200;

  return materials.slice(0, amount).map(material =>
    <MaterialsListItem key={material.id} material={material}/>
  )
}

const mapStateToProps = createStructuredSelector({
  materials: selectFilteredMaterials,
})

export default connect(mapStateToProps)(MaterialsList)