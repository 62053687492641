import React from 'react';
import {Link} from 'react-router-dom';
import {Modal} from "antd";
import Video from "./viewers/Video";
import VideoByLink from "./viewers/VideoByLink";
import Doc from "./viewers/Doc";
import ImageViewer from "./viewers/ImageViewer";
import Audio from "./viewers/Audio";
import LinkViewer from "./viewers/LinkViewer";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../redux/user/user.selectors";
import {selectPreviewModal} from "../redux/materials/materials.selectors";
import {connect} from "react-redux";
import {closePreview} from "../redux/materials/materials.actions";

const PreviewModal = ({currentUser, dispatch, previewModal}) => {
    const {status, title, fileType, videoId, videoLink, filesLinks, id, kitmatTitle, link, isDownloadBlocked} = previewModal;

    return (
        <Modal
            wrapClassName="preview-modal"
            destroyOnClose
            visible={status}
            onCancel={() => dispatch(closePreview())}
            footer={null}
        >
            <div className="ant-modal-header">
                <div className="ant-modal-title">
                    {
                        title &&
                        <Link
                            to={`/m/${id}`}
                            onClick={() => dispatch(closePreview())}
                        >
                            <div style={{paddingRight: 50}}>{title}</div>
                        </Link>
                    }

                    {kitmatTitle && <div style={{paddingRight: 50}}>{kitmatTitle}</div>}
                </div>
            </div>

            {fileType === 'video' &&
            <Video videoId={videoId}/>
            }
            {fileType === 'videoLink' &&
            <VideoByLink videoLink={videoLink}/>
            }
            {(fileType === 'pdf' || fileType === 'doc' || fileType === 'ppt') &&
            <Doc filesLinks={filesLinks} isApproved={currentUser && currentUser.isApproved} isDownloadBlocked={isDownloadBlocked}/>
            }
            {fileType === 'images' &&
            <ImageViewer filesLinks={filesLinks}/>
            }
            {fileType === 'audio' &&
            <Audio filesLinks={filesLinks}/>
            }
            {fileType === 'link' &&
            <LinkViewer link={link}/>
            }

        </Modal>
    )
}


const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    previewModal: selectPreviewModal,
});

export default connect(mapStateToProps)(PreviewModal)